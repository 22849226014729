#firepad-container {
    height: 400px;
    margin: auto;
    background-color: #ced4da; 
}

.CodeMirror {
    margin: auto;
    border: 1px solid #ced4da;
  }
  
  a.firepad-btn, a.firepad-btn:visited, a.firepad-btn:active {
    font-family: "Arial" sans-serif;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    padding: 6px 6px 4px 6px;
    text-align: center;
    vertical-align: middle;
    font-size: 16px;
    background-color: #ced4da;
    border: 1px solid #c9c9c9;
    border-bottom-width: 4px;
    color: black;
  }
